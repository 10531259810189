export const environment = {
  production: false,
  region: 'US_EAST_1',
  identityPoolId: '',
  userPoolId: 'us-east-1_ALjhxO9eZ',
  clientId: 'k0uks1e4jv3u8up7gdg7pv562',
  cognito_idp_endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_ALjhxO9eZ',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  baseUrl_IncomingQueue: 'https://exp-api.getcamber.com/exp-iq-dev-launch2020',
  baseUrl_Bus_CCSS_Display: 'https://exp-api.getcamber.com/exp-datalake-dev-launch2020',
  baseUrl_RecommendedRun: 'https://exp-api.getcamber.com/exp-ccss-dev-launch2020',
  baseUrl_Garage_Tracks: 'https://exp-api.getcamber.com/exp-ccss-dev-launch2020',
  baseUrl_CustomerAssets: 'https://exp-api.getcamber.com/exp-ams-dev-launch2020',
  // APEX_URL: "http://localhost:9000",
  APEX_URL: 'https://exp-dev-launch2020.proterra.com',
  AGREEMENT_URL : 'https://exp-api.getcamber.com/docusign-dev-launch2020/v1'
};
